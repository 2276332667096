import React, { useState, useEffect } from "react";
import { Table, Button, DatePicker, message, Tag, PageHeader,Input, Row, Col } from "antd";
import axios from "axios";
import moment from "moment";
import endpoint from "../../helpers/Endpoint";
const { MonthPicker, WeekPicker } = DatePicker;

const OldRest = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("isoWeek").add(1, "days")); // Monday
  const [dateRange, setDateRange] = useState("");

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);



  useEffect(()=>{
    let date = new Date();
    // let startDate = moment(date).startOf("month").add(1, "days")._d;
    // let endDate =moment(date).endOf("month").add(1, "days")._d;
    let startDate= moment(date).startOf("week").add(2, "days");
    let endDate=moment(date).endOf("week").add(2, "days");
    fetchData(startDate , endDate);
    console.log("intial data =====", startDate , endDate );
  },[])

  // Fetch data from API
  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    const token = localStorage.getItem("token");
  
    try {
      const response = await axios.post(
        `${endpoint}/admin//getOldOppgorOnly`,
        {
          startDate,
          endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("BeDate", response.data.BeDate);
      setData(response.data.data);
      setFilteredData(response.data.data);
      setDateRange(response.data.BeDate);
    } catch (error) {
      message.error("Failed to fetch data");
    }
  
    setLoading(false);
  };
  



  const handleDateChange = (date, type) => {
    let postdata = {};
    let day = "";
    day = type;
    // console.log("type==========" , day )

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days"),
        };
        break;
      case "w":
        postdata = {
          startDate: moment(date).startOf("week").add(2, "days"),
          endDate: moment(date).endOf("week").add(2, "days"),
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days"),
        };
    }
    fetchData(postdata.startDate.toISOString(), postdata.endDate.toISOString());
  };
  // Handle "Send" button click
  const handleSend = async (record) => {
    try {
      await axios.post(`${endpoint}/admin/sendPayment`, { branchId: record.branchId });
      message.success("Payment sent successfully");
    } catch {
      message.error("Failed to send payment");
    }
  };

  // Navigate back
  const goBack = () => {
    props.history.push("/payments");
  };

  const handleSearch = (value, key) => {
    const filtered = data.filter((item) =>
      item[key]?.toString().toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // Table columns
  const columns = [
    {
      title: "Branch Name",
      key: "branchName",
      render: (_, record) => (
        <strong>{record.branchName}</strong>
      ),
    },
    {
      title: "Invoice Amount",
      key: "remAmt",
      render: (_, record) => (
        <strong>{record.OPPGJØR}</strong>
      ),
    },
    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "SUCCESS" ? "green" : "red"}>
          {status ? status : "Failed"}
        </Tag>
      )
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSend(record)}>
          Payment Proceed
        </Button>
      ),
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header oldRestNewRest">
      <Row style={{width:'100%'}}>
        <Col span={10} >
        <h2>
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-arrow-left" onClick={goBack}></i>
              </span>
              <span>Back</span>
            </span>
          </h2>
          </Col>
        <Col span={14} className="d-flex flex column p-0">  
        <div className="d-flex" style={{textAlign:'right'}}>
        <span>
            <strong className="heading2"> {moment(dateRange.startDate).format('MMM DD, YYYY')} 04:00 am to {moment(dateRange.endDate).format('MMM DD, YYYY')}  03:59 am</strong>
          </span>
        </div>
        <div style={{ float: "right", display: "flex", alignItems: "center", gap: "10px", marginTop:'10px' }}>
          <div className=" d-flex m-3 justify-content-evenly">
        <DatePicker onChange={(e) => handleDateChange(e, "d")} />
        <WeekPicker
          onChange={(e) => handleDateChange(e, "w")}
          placeholder="Select week"
        />
        <MonthPicker
          placeholder="Select Month"
          onChange={(e) => handleDateChange(e, "m")}
        />
      </div>
        </div></Col>
      </Row>
      
     
      </PageHeader>

      <Table columns={columns}    dataSource={filteredData} loading={loading} rowKey="branchId" />
    </div>
  );
};

export default OldRest;
