import React, { Component, Fragment } from "react";
import Restaurant from "../restaurant/Restaurant";
import Notification from "react-bulma-notification";
import endpoint from "../../helpers/Endpoint";
import s3url from "../../helpers/s3";
import { Badge, Select } from "antd";
import { Tag, Row } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
const { Option } = Select;

class RestaurantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurants: [],
      restType: "active",
      superCats: [],
      restaurant: [],
      isLoaded: false,
      currentPage: "restaurantList",
      isActive: false,
      activeIdLive: "",
      activeId: "",
      style: ""
    };
  }

  componentDidMount = () => {
    this.getRestaurants();
  };

  getRestaurants = () => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/restaurant`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          this.setState({
            restaurants: [],
            isLoaded: true
          });
        } else {
          let data = result.data.filter((item) => !item.isDeleted);
          if (this.state.restType == "inActive") {
            data = data.filter((item) => !item.isActive);
          } else if (this.state.restType == "active") {
            data = data.filter((item) => item.isActive);
          }

          console.log("-Data-", data);
          this.setState({
            restaurants: data,
            isLoaded: true,
            isQrCode: data.isQrCode
          });

          localStorage.setItem("resList", JSON.stringify(data));
        }
      })
      .catch((error) => console.log(error));
  };
  getsuperCats = (id) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/prcat/adminsupercat/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
          // console.log(result.data1);
        }
        if (result) {
          console.log("Getting data super cats", result);
          // const data = result.data.filter(item => !item.isDeleted);
          this.setState({ superCats: result.data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  showRestaurant = (restaurant) => {
    console.log("you are getting iD in restaurants", restaurant._id);
    this.getsuperCats(restaurant._id);
    this.setState({ currentPage: "singleRestaurant", restaurant });
  };

  // toggleDropdown = activeId => {
  //   this.setState(prevState => ({ isActive: !prevState.isActive, activeId }));
  // };
  radioLiveTester = (e, id) => {
    // this.setState(prevState => ({ activeId: !prevState.activeId }));
    console.log(id);
    console.log(e.target.name);
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }

    const data = JSON.stringify({
      isTest: e.target.name === "test" ? true : false
    });

    console.log(data);

    fetch(`${endpoint}/admin/restaurant/test/${id}`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
        } else {
          console.log(result.data);
          this.getRestaurants();
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  radioActiveInActive = (e, activeId) => {
    // this.setState(prevState => ({ activeId: !prevState.activeId }));
    console.log(activeId);
    console.log(e.target.name);
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }

    const data = JSON.stringify({
      isActive: e.target.name === "active" ? true : false
    });

    console.log(data);

    fetch(`${endpoint}/admin/restaurant/active/${activeId}`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
        } else {
          console.log(result.data);
          this.getRestaurants();
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  openModal = (id) => {
    this.setState({ activeDelete: id, style: "is-active" });
  };

  closeModal = () => {
    this.setState({ style: "" });
  };

  deleteRestaurant = () => {
    const { activeDelete } = this.state;
    const token = localStorage.getItem("token");

    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }

    fetch(`${endpoint}/admin/restaurant/${activeDelete}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
        } else {
          console.log(result.data);
          this.getRestaurants();
          this.setState({ style: "" });
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  handleClickBack = () => {
    this.setState(
      {
        currentPage: "restaurantList",
        isLoaded: false
      },
      this.getRestaurants
    );
  };

  selectRestType = async (value) => {
    console.log("selectRestType", value);
    await this.setState({
      restType: value
    });
    this.getRestaurants();
  };
  render() {
    const { restaurants, isLoaded, restaurant, currentPage, superCats, style } =
      this.state;
    let authenticated;
    if (localStorage.getItem("loggedIn") === "true") {
      authenticated = true;
    } else {
      authenticated = false;
    }

    if (!authenticated) {
      return <Fragment />;
    }

    if (!isLoaded) {
      return <Fragment>Loading...</Fragment>;
    } else if (currentPage === "restaurantList") {
      return (
        <div
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            borderRadius:10
          }}
        >
          <div className="columns is-centered">
            <table
              className="table is-fullwidth fixTableHead"
              id="mainDataDisplay"
            >
              <thead>
                <tr>
                  <th>Logo{/* <abbr title="Serial Number">S.No.</abbr> */}</th>
                  <th>Name</th>
                  {/* <th>E-Mail</th>
                  <th>Phone No.</th> */}
                  {/* <th>Address</th> */}
                  {/* <th>Icon</th> */}
                  <th>Reports</th>
                  <th>Permissions</th>
                  <th>Acc. Info</th>
                  {/* <th>Active/InActive</th> */}
                  <th className="accStat">
                    {/* Acc. Status{" "} */}
                    {/* <span> */}
                    <Select
                      defaultValue="active"
                      style={{ margin: 0, width: 180 }}
                      onChange={ this.selectRestType}

                      value={this.state.restType}
                     
                    >
                      <Option value="all">Acc. Status (All)</Option>
                      <Option value="active">Acc. Status (Active)</Option>
                      <Option value="inActive">Acc. Status (InActive)</Option>
                    </Select>
                    {/* </span> */}
                  </th>
                  <th>E-Mail/ Phone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {restaurants.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td style={{ width: "6%" }}>
                        <img
                          src={item.logo ? `${s3url}/${item.logo}` : ""}
                          style={{
                            maxWidth: "100px",
                            height: "45px",
                            borderRadius: "10%"
                          }}
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        <a onClick={() => this.showRestaurant(item)}>
                          <b> {item.name}</b>
                        </a>
                        &nbsp;
                        <Badge
                          dot={
                            item.minDepositAllowed - item.remDeposit >
                            item.minDiffAllowed
                              ? true
                              : false
                          }
                        ></Badge>
                        <p>{item.address.city}</p>
                      </td>
                      <td style={{width: "5%"}}>
                        {item.isNewReports == true ? (
                          <Tag color="#fa541c">New</Tag>
                        ) : (
                          <Tag color="#CCA249">Old</Tag>
                        )}
                      </td>
                      <td>
                        {item.isActive == true ? (
                          <Row style={{ display: "flex" }}>
                            {item.isTreeDriveAllowed == true && (
                              <Tag color="#12c751">TD</Tag>
                            )}
                            {item.machAccess !== "treedrive" && (
                              <Tag color="#1b3045">ORC</Tag>
                            )}
                          </Row>
                        ) : (
                          ""
                        )}
                      </td>
                      <td style={{ width: "10%" }}>
                        <form>
                          <div
                            className={`control ${
                              this.state.activeIdLive === item._id
                            }`}
                          >
                            <label className="radio">
                              <input
                                type="radio"
                                name="live"
                                onChange={(e) => {
                                  this.radioLiveTester(e, item._id);
                                }}
                                checked={!item.isTest}
                              />
                              &nbsp;Live
                            </label>
                            <br />
                            <label className="radio">
                              <input
                                type="radio"
                                name="test"
                                onChange={(e) => {
                                  this.radioLiveTester(e, item._id);
                                }}
                                checked={item.isTest}
                              />
                              &nbsp;Tester
                            </label>
                          </div>
                        </form>
                      </td>
                      <td style={{width: "10%"}}>
                        <form>
                          <div
                            className={`control ${
                              this.state.activeId === item._id
                            }`}
                          >
                            <label className="radio">
                              <input
                                type="radio"
                                name="active"
                                onChange={(e) => {
                                  this.radioActiveInActive(e, item._id);
                                }}
                                checked={item.isActive}
                              />
                              &nbsp;Active
                            </label>
                            <br />
                            <label className="radio">
                              <input
                                type="radio"
                                name="inactive"
                                onChange={(e) => {
                                  this.radioActiveInActive(e, item._id);
                                }}
                                checked={!item.isActive}
                              />
                              &nbsp;Inactive
                            </label>
                          </div>
                        </form>
                      </td>
                      <td style={{ width: "20%" }}>
                        {item.email}
                        <br />
                        {item.phoneNo}
                      </td>
                      {/* <td style={{ width: "10%" }}>{item.phoneNo}</td> */}
                      {/* <td style={{ width: "10%" }}>{item.address.city}</td> */}
                      <td style={{ width: "10%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <button
                            className="button is-danger"
                            onClick={() => this.openModal(item._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={`modal ${style}`}>
            <div className="modal-background" />
            <div className="modal-content" style={{ zIndex: 1 }}>
              <div className="tile is-parent">
                <article className="tile is-child notification ">
                  <div className="content">
                    {/* <label className="file-label">
                      <input
                        style={{ color: '#333' }}
                        className="file-input"
                        type="file"
                        name="icon"
                        onChange={this.handleIconChange}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label">Choose a file…</span>
                      </span>
                    </label> */}

                    <p>Are you sure you want to delete this restaurant?</p>

                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={this.deleteRestaurant}
                      >
                        Yes
                      </button>
                      {"  "}
                      <button className="button" onClick={this.closeModal}>
                        No
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div
              onClick={this.closeModal}
              style={{
                minWidth: "100%",
                minHeight: "100%",
                position: "absolute"
              }}
            >
              <button className="modal-close is-large" aria-label="close" />
            </div>
          </div>
        </div>
      );
    } else if (currentPage === "singleRestaurant") {
      return (
        <Fragment>
          <Row style={{ display: "flex", justifyContent: "end" }}>
            <button
              onClick={this.handleClickBack}
              className="button"
              style={{ backgroundColor: "#363636", color: "#fff" }}
            >
              {/* Back */}
              <DoubleLeftOutlined />
            </button>
          </Row>
          <Restaurant
            currentPage={this.state.currentPage}
            superCats={superCats}
            restaurant={restaurant}
            getsuperCats={(id) => this.getsuperCats(id)}
          />
        </Fragment>
      );
    } else {
      return <div>error!!</div>;
    }
  }
}

export default RestaurantList;
