import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  DatePicker,
  Tag,
  PageHeader,
  Row,
  Col,
  notification,
  Input,
  Modal,
  Select,
  message,
} from "antd";

import axios from "axios";
import moment from "moment";
import endpoint from "../../helpers/Endpoint";

const NewRest = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [branchFilter, setBranchFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [amount, setAmount] = useState("");
  const [processedPayments, setProcessedPayments] = useState(() => {
    return JSON.parse(localStorage.getItem("processedPayments")) || [];
  });

  useEffect(() => {
    let date = new Date();
    let startDate = moment(date).hour(4).minute(0);
    let endDate = moment(startDate).add(1, "days").hour(3).minute(59);
    fetchData(startDate, endDate);
  }, []);

  const getBranchList = async () => {
    try {
      let result = await fetch(`${endpoint}/admin/restaurantList`, {
        method: "Get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((result) => result.json())
        .then((result) => {
          if (result.status === "failure") {
            console.log("THERE IS A FAULURE ");
          }
          if (result) {
            // setBranch(result.data);
            setRestaurants(result.data[0].data);
            // setVendors(result.data[1].data);
            // setRetailers(result.data[2].data);
          }
        });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    // Convert dates to YYYY-MM-DD format for correct comparison
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    try {
      const response = await axios.post(
        `${endpoint}/admin/getOppgorsOnly`,
        { startDate, endDate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      console.log("response.data", response.data.data);

      setData(response.data.data);
      setDateRange({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    } catch (error) {
      notification.error({ message: "Failed to fetch data" });
    }
    setLoading(false);
  };

    const handleAmountChange = (value, record) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.branchId === record.branchId
          ? { ...item, amount: value }
          : item
      )
    );
  };


    const handleSave = (record) => {
    setEditingRow(null);
    notification.success({ message: "Amount saved successfully" });
  };


  
  const handleProceedPayment = async (record, savedId = null) => {
    try {
      const payload = {
        branchId: record.branchId,
        amount: record.amount,
      };
      if (savedId) payload.orderId = savedId;
      setModalVisible(false);

      const response = await axios.post(
        `${endpoint}/api/v1/admin/autoPay/manualPayment`,
        payload
      );

      const responseData = response.data.data[0];
      if (responseData.paymentStatus === "AUTHORIZED") {
        notification.success({ message: "Payment processed successfully" });

        const formattedStartDate = moment(dateRange.startDate).format(
          "YYYY-MM-DD"
        );
        const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");

        const newProcessedPayments = [
          ...processedPayments,
          {
            branchId: record.branchId,
            amount: record.amount,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
        ];

        setProcessedPayments(newProcessedPayments);
        localStorage.setItem(
          "processedPayments",
          JSON.stringify(newProcessedPayments)
        );

        setData((prevData) =>
          prevData.map((item) =>
            item.branchId === record.branchId &&
            item.amount === record.amount &&
            formattedStartDate ===
              moment(dateRange.startDate).format("YYYY-MM-DD") &&
            formattedEndDate === moment(dateRange.endDate).format("YYYY-MM-DD")
              ? { ...item, status: "SUCCESS" }
              : item
          )
        );
      } else {
        notification.error({
          message: "Payment failed",
          description: "Re-attempting with saved ID...",
          duration: 4,
        });
        if (responseData.savedId)
          handleProceedPayment(record, responseData.savedId);
      }
    } catch (error) {
      notification.error({ message: "Failed to process payment", duration: 4, });
    }
  };

  
  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      filters: branchFilter,
      onFilter: (value, record) => record.branchName === value,
    },
    // {
    //   title: "Invoice Amount",
    //   dataIndex: "amount",
    //   key: "amount",
    //   sorter: (a, b) => a.amount - b.amount,
    //   render: (text, record) => <span>{record.amount}</span>,
    // },
    {
      title: "Invoice Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) =>
        editingRow === record.branchId ? (
          <Input
            type="number"
            value={record.amount}
            onChange={(e) => handleAmountChange(e.target.value, record)}
          />
        ) : (
          <span>{record.amount}</span>
        ),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: statusFilter,
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag color={status === "SUCCESS" ? "green" : status === "NotProcessed" ? "skyblue" : "red"}>
        {status}
      </Tag>
      
      ),
    },
    {
      title: "Edit Invoice Amount",
      key: "edit",
      render: (_, record) =>
        record.status === "SUCCESS" || record.amount == 0 ? null : (
          <>
            {editingRow === record.branchId ? (
              <>
                <Button type="primary" onClick={() => handleSave(record)}>
                  Save
                </Button>
                <Button
                  style={{ marginLeft: "8px" }}
                  onClick={() => setEditingRow(null)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <button
                className="button is-primary "
                onClick={() => setEditingRow(record.branchId)}
              >
                Edit Invoice Amount
                {/* <i className="fa fa-edit" /> */}
              </button>
            )}
          </>
        ),
    },
    {
      title: "Action",
      key: "proceedPayment",
      render: (_, record) =>
        record.status === "SUCCESS" || record.amount == 0 ? null : (
          <Button type="primary" onClick={() => handleProceedPayment(record)}>
            Proceed Payment
          </Button>
        ),
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header oldRestNewRest">
        <Row style={{ width: "100%" }}>
          <Col span={10}>
            <h2>
              <span className="icon-text">
                <span className="icon">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => props.history.push("/payments")}
                  ></i>
                </span>
                <span>Back</span>
              </span>
            </h2>
          </Col>
          <Col span={14}>
            <div className="d-flex" style={{ textAlign: "right" }}>
              <strong className="heading2">
                {moment(dateRange.startDate).format("MMM DD, YYYY")} 04:00 am to{" "}
                {moment(dateRange.endDate).format("MMM DD, YYYY")} 03:59 am
              </strong>
            </div>
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <DatePicker
                onChange={(e) =>
                  fetchData(
                    moment(e).hour(4).minute(0),
                    moment(e).add(1, "days").hour(3).minute(59)
                  )
                }
              />
            </div>
          </Col>
        </Row>
      </PageHeader>
      <Row>
        <Col>
          <div
            className="d-flex"
            style={{ textAlign: "left", paddingBottom: "10px" }}
          >
            <Button
              onClick={() => {
                setModalVisible(true);
                getBranchList();
              }}
            >
              Custom Pay
            </Button>
          </div>
          <>
            <Modal
              title="Custom Payment"
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <Select
                style={{ width: "100%", marginBottom: 16 }}
                placeholder="Select a restaurant"
                onChange={setSelectedRestaurant}
              >
                {restaurants.map((restaurant) => (
                  <Select.Option key={restaurant._id} value={restaurant._id}>
                    {restaurant.name}
                  </Select.Option>
                ))}
              </Select>

              <Input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ marginBottom: 16 }}
              />

              <Button
                type="primary"
                //  onClick={handleProceed}
                block
              >
                Proceed
              </Button>
            </Modal>
          </>
        </Col>
      </Row>
      <Table
               columns={columns}
                dataSource={data}
                loading={loading}
                rowKey="branchId"
              />
    </div>
  );
};

export default NewRest;

// import React, { useState, useEffect } from "react";
// import {
//   Table,
//   Button,
//   DatePicker,
//   Tag,
//   PageHeader,
//   Row,
//   Col,
//   notification,
//   Input,
//   Modal,
//   Select,
//   message,
// } from "antd";
// import axios from "axios";
// import moment from "moment";
// import endpoint from "../../helpers/Endpoint";

// const { MonthPicker, WeekPicker } = DatePicker;

// const NewRest = (props) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [dateRange, setDateRange] = useState("");
//   const [branchFilter, setBranchFilter] = useState([]);
//   const [statusFilter, setStatusFilter] = useState([]);
//   const [editingRow, setEditingRow] = useState(null);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [restaurants, setRestaurants] = useState([]);
//   const [selectedRestaurant, setSelectedRestaurant] = useState(null);
//   const [amount, setAmount] = useState("");
//   const [processedPayments, setProcessedPayments] = useState(() => {
//     return JSON.parse(localStorage.getItem("processedPayments")) || [];
//   });
//   useEffect(() => {
//     let date = new Date();
//     let startDate = moment(date).startOf("isoWeek").hour(4).minute(0);
//     let endDate = moment(startDate).add(7, "days").hour(3).minute(59);
//     fetchData(startDate, endDate);
//   }, []);

//   const getBranchList = async () => {
//     try {
//       let result = await fetch(`${endpoint}/admin/restaurantList`, {
//         method: "Get",
//         headers: {
//           "x-auth-token": localStorage.getItem("token"),
//         },
//       })
//         .then((result) => result.json())
//         .then((result) => {
//           if (result.status === "failure") {
//             console.log("THERE IS A FAULURE ");
//           }
//           if (result) {
//             // setBranch(result.data);
//             setRestaurants(result.data[0].data);
//             // setVendors(result.data[1].data);
//             // setRetailers(result.data[2].data);
//           }
//         });
//     } catch (error) {
//       console.log("something went wrong");
//     }
//   };

//   const fetchData = async (startDate, endDate) => {
//     setLoading(true);
//     const token = localStorage.getItem("token");

//     // Convert dates to YYYY-MM-DD format for correct comparison
//     const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
//     const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

//     try {
//       const response = await axios.post(
//         `${endpoint}/admin/getNewOppgorOnly`,
//         { startDate, endDate },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             "x-auth-token": token,
//           },
//         }
//       );

//       const updatedData = response.data.data.map((item) => ({
//         ...item,
//         startDate: formattedStartDate, // Store formatted startDate
//         endDate: formattedEndDate, // Store formatted endDate
//         status: processedPayments.some(
//           (p) =>
//             p.branchId === item.branchId &&
//             p.invoiceAmount === item.invoiceAmount &&
//             p.startDate === formattedStartDate && // Compare startDate properly
//             p.endDate === formattedEndDate // Compare endDate properly
//         )
//           ? "SUCCESS"
//           : "NOT-PROCESSED",
//       }));

//       setData(updatedData);
//       setDateRange({
//         startDate: formattedStartDate,
//         endDate: formattedEndDate,
//       });

//       // Generate filters dynamically
//       setBranchFilter(
//         [...new Set(updatedData.map((item) => item.branchName))].map(
//           (name) => ({
//             text: name,
//             value: name,
//           })
//         )
//       );

//       setStatusFilter([
//         { text: "SUCCESS", value: "SUCCESS" },
//         { text: "NOT-PROCESSED", value: "NOT-PROCESSED" },
//       ]);
//     } catch (error) {
//       notification.error({ message: "Failed to fetch data" });
//     }
//     setLoading(false);
//   };

//   const handleAmountChange = (value, record) => {
//     setData((prevData) =>
//       prevData.map((item) =>
//         item.branchId === record.branchId
//           ? { ...item, invoiceAmount: value }
//           : item
//       )
//     );
//   };
//   const handleSave = (record) => {
//     setEditingRow(null);
//     notification.success({ message: "Amount saved successfully" });
//   };

//   const handleProceedPayment = async (record, savedId = null) => {
//     try {
//       const payload = {
//         branchId: record.branchId,
//         amount: record.invoiceAmount,
//       };
//       if (savedId) payload.orderId = savedId;
//       setModalVisible(false);

//       const response = await axios.post(
//         `${endpoint}/api/v1/admin/autoPay/manualPayment`,
//         payload
//       );

//       const responseData = response.data.data[0];
//       if (responseData.paymentStatus === "AUTHORIZED") {
//         notification.success({ message: "Payment processed successfully" });

//         const formattedStartDate = moment(dateRange.startDate).format(
//           "YYYY-MM-DD"
//         );
//         const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");

//         const newProcessedPayments = [
//           ...processedPayments,
//           {
//             branchId: record.branchId,
//             invoiceAmount: record.invoiceAmount,
//             startDate: formattedStartDate,
//             endDate: formattedEndDate,
//           },
//         ];

//         setProcessedPayments(newProcessedPayments);
//         localStorage.setItem(
//           "processedPayments",
//           JSON.stringify(newProcessedPayments)
//         );

//         setData((prevData) =>
//           prevData.map((item) =>
//             item.branchId === record.branchId &&
//             item.invoiceAmount === record.invoiceAmount &&
//             formattedStartDate ===
//               moment(dateRange.startDate).format("YYYY-MM-DD") &&
//             formattedEndDate === moment(dateRange.endDate).format("YYYY-MM-DD")
//               ? { ...item, status: "SUCCESS" }
//               : item
//           )
//         );
//       } else {
//         notification.error({
//           message: "Payment failed",
//           description: "Re-attempting with saved ID...",
//           duration: 4,
//         });
//         if (responseData.savedId)
//           handleProceedPayment(record, responseData.savedId);
//       }
//     } catch (error) {
//       notification.error({ message: "Failed to process payment", duration: 4, });
//     }
//   };

//   const handleProceed = () => {
//     if (!selectedRestaurant || !amount) {
//       message.warning("Please select a restaurant and enter the amount.");
//       return;
//     }

//     const payload = {
//       branchId: selectedRestaurant, // Aligning with handleProceedPayment function
//       invoiceAmount: parseFloat(amount),
//     };

//     handleProceedPayment(payload);
//   };

//   const columns = [
//     {
//       title: "Branch Name",
//       dataIndex: "branchName",
//       key: "branchName",
//       filters: branchFilter,
//       onFilter: (value, record) => record.branchName === value,
//     },
//     // {
//     //   title: "Invoice Amount",
//     //   dataIndex: "invoiceAmount",
//     //   key: "invoiceAmount",
//     //   sorter: (a, b) => a.invoiceAmount - b.invoiceAmount,
//     //   render: (text, record) => <span>{record.invoiceAmount}</span>,
//     // },
//     {
//       title: "Invoice Amount",
//       dataIndex: "invoiceAmount",
//       key: "invoiceAmount",
//       render: (text, record) =>
//         editingRow === record.branchId ? (
//           <Input
//             type="number"
//             value={record.invoiceAmount}
//             onChange={(e) => handleAmountChange(e.target.value, record)}
//           />
//         ) : (
//           <span>{record.invoiceAmount}</span>
//         ),
//       sorter: (a, b) => a.invoiceAmount - b.invoiceAmount,
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//       filters: statusFilter,
//       onFilter: (value, record) => record.status === value,
//       render: (status) => (
//         <Tag color={status === "SUCCESS" ? "green" : "skyblue"}>{status}</Tag>
//       ),
//     },
//     {
//       title: "Edit Invoice Amount",
//       key: "edit",
//       render: (_, record) =>
//         record.status === "SUCCESS" || record.invoiceAmount == 0 ? null : (
//           <>
//             {editingRow === record.branchId ? (
//               <>
//                 <Button type="primary" onClick={() => handleSave(record)}>
//                   Save
//                 </Button>
//                 <Button
//                   style={{ marginLeft: "8px" }}
//                   onClick={() => setEditingRow(null)}
//                 >
//                   Cancel
//                 </Button>
//               </>
//             ) : (
//               <button
//                 className="button is-primary "
//                 onClick={() => setEditingRow(record.branchId)}
//               >
//                 Edit Invoice Amount
//                 {/* <i className="fa fa-edit" /> */}
//               </button>
//             )}
//           </>
//         ),
//     },
//     {
//       title: "Action",
//       key: "proceedPayment",
//       render: (_, record) =>
//         record.status === "SUCCESS" || record.invoiceAmount == 0 ? null : (
//           <Button type="primary" onClick={() => handleProceedPayment(record)}>
//             Proceed Payment
//           </Button>
//         ),
//     },
//   ];

//   return (
//     <div>
//       <PageHeader className="site-page-header oldRestNewRest">
//         <Row style={{ width: "100%" }}>
//           <Col span={10}>
//             <h2>
//               <span className="icon-text">
//                 <span className="icon">
//                   <i
//                     className="fas fa-arrow-left"
//                     onClick={() => props.history.push("/payments")}
//                   ></i>
//                 </span>
//                 <span>Back</span>
//               </span>
//             </h2>
//           </Col>
//           <Col span={14}>
//             <div className="d-flex" style={{ textAlign: "right" }}>
//               <strong className="heading2">
//                 {moment(dateRange.startDate).format("MMM DD, YYYY")} 04:00 am to{" "}
//                 {moment(dateRange.endDate).format("MMM DD, YYYY")} 03:59 am
//               </strong>
//             </div>
//             <div
//               style={{
//                 float: "right",
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "10px",
//                 marginTop: "10px",
//               }}
//             >
//               <DatePicker
//                 onChange={(e) =>
//                   fetchData(
//                     moment(e).hour(4).minute(0),
//                     moment(e).add(1, "days").hour(3).minute(59)
//                   )
//                 }
//               />

//               <WeekPicker
//                 onChange={(e) =>
//                   fetchData(
//                     moment(e)
//                       .startOf("isoWeek")
//                       .add(0, "days")
//                       .hour(4)
//                       .minute(0),
//                     moment(e)
//                       .startOf("isoWeek")
//                       .add(7, "days")
//                       .hour(3)
//                       .minute(59)
//                   )
//                 }
//                 placeholder="Select week"
//               />

//               <MonthPicker
//                 onChange={(e) =>
//                   fetchData(
//                     moment(e).startOf("month").hour(4).minute(0),
//                     moment(e)
//                       .add(1, "months")
//                       .startOf("month")
//                       .hour(3)
//                       .minute(59)
//                   )
//                 }
//                 placeholder="Select Month"
//               />
//             </div>
//           </Col>
//         </Row>
//       </PageHeader>
//       <Row>
//         <Col>
//         <div className="d-flex" style={{ textAlign: "left", paddingBottom:"10px" }}>
//         <Button
//               onClick={() => {
//                 setModalVisible(true);
//                 getBranchList();
//               }}
//             >
//               Custom Pay
//             </Button>
//             </div>
//           <>

//             <Modal
//               title="Custom Payment"
//               visible={modalVisible}
//               onCancel={() => setModalVisible(false)}
//               footer={null}
//             >
//               <Select
//                 style={{ width: "100%", marginBottom: 16 }}
//                 placeholder="Select a restaurant"
//                 onChange={setSelectedRestaurant}
//               >
//                 {restaurants.map((restaurant) => (
//                   <Select.Option key={restaurant._id} value={restaurant._id}>
//                     {restaurant.name}
//                   </Select.Option>
//                 ))}
//               </Select>

//               <Input
//                 type="number"
//                 placeholder="Enter amount"
//                 value={amount}
//                 onChange={(e) => setAmount(e.target.value)}
//                 style={{ marginBottom: 16 }}
//               />

//               <Button type="primary" onClick={handleProceed} block>
//                 Proceed
//               </Button>
//             </Modal>
//           </>
//         </Col>
//       </Row>
//       <Table
//         columns={columns}
//         dataSource={data}
//         loading={loading}
//         rowKey="branchId"
//       />
//     </div>
//   );
// };

// export default NewRest;
