import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Avatar, Badge, Space, Row, Col } from "antd";
import endpoint from "../../helpers/Endpoint";
import moment from "moment";
import "../../App.css"; // Assuming you have a CSS file for styling

const Sidebar = () => {
  // let weeklyCancelReportCount=0;
  let [weeklyCancelReportCount,setWeeklyCancelReportCount]=useState(0)
  useEffect(()=>{
    console.log("first render in useEffect sideBar======>");
    getRestReports();
  },[]);
  let getRestReports = (date) => {
    var hdr = localStorage.token;
    var postData = JSON.stringify({
      startDate: moment(date),
      endDate: moment(date).add(1, "days"),
      isMonth: false
    });
    fetch(`${endpoint}/admin/singleadminreportweeklycancelled`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(
          "result in dashboard in weeklycancelledreport=====>",
          result
        );
        if (result.status === 404) {
          setWeeklyCancelReportCount(0);
        } else {
          setWeeklyCancelReportCount(result.data.length);
        }
        localStorage.setItem(
          "weeklyCancelReportCount",
          weeklyCancelReportCount
        );
      });
  };

  const menuItems = [
    { path: "/", label: "List Restaurants" },
    { path: "/add-restaurant", label: "Add Restaurants" },
    { path: "/add-icons", label: "Add Icons" },
    { path: "/adminIcons/list", label: "List Admin Icons" },
    { path: "/adminIcons/add", label: "Add Admin Icons" },
    { path: "/oppgjør-tables", label: "Oppgjør Tables" },
    { path: "/admin-payment", label: "Admin Payment" },
    { path: "/devices", label: "Devices" },
    { path: "/adminreports", label: "Admin Reports" },
    {
      path: "/adminreportsweeklycancelled",
      label: "Cancelled Report",
      hasBadge: true
    },
    { path: "/coupons", label: "TFL Gift Card Details" },
    { path: "/inprocessinvoices", label: "In-Process Invoices" },
    { path: "/reservetable", label: "Reserve Table Price" },
    { path: "/logindetails", label: "Login Details" },
    { path: "/cloudpaymentrecords", label: "Cloud Records" },
    { path: "/advertisements", label: "Advertisements" },
    { path: "/invitations", label: "Invitations" },
    { path: "/payments", label: "Payment Section" }
  ];

  return (
    <aside className="menu">
      <Row gutter={[8, 8]} className="menu-list" style={{ flexWrap: "wrap" }}>
        {menuItems.map(({ path, label, hasBadge }) => (
          <Col className="gutter-row" span={4} key={path}>
            {hasBadge ? (
              <Badge count={weeklyCancelReportCount}>
                <NavLink
                  exact
                  to={path}
                  activeClassName="is-active"
                  onClick={() => setWeeklyCancelReportCount(0)}
                >
                  {label}
                </NavLink>
              </Badge>
            ) : (
              <NavLink exact to={path} activeClassName="is-active">
                {label}
              </NavLink>
            )}
          </Col>
        ))}
      </Row>
    </aside>
  );
};

export default Sidebar;
