import React, { Fragment } from "react";
import companyLogo from "../../assets/Aryan-Holding-Group-Logo.png";

const Header = (props) => {
  const style = {
    marginBottom: "1rem"
  };
  const { loggedIn } = props;
  return (
    <Fragment>
      <nav
        className="navbar is-dark"
        role="navigation"
        aria-label="main navigation"
        style={loggedIn ? style : {}}
      >
        <div className="container is-widescreen">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={companyLogo} width="150" />
            </a>

            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start" />
            {loggedIn ? (
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    <a
                      className="button is-link"
                      onClick={props.changePassword}
                    >
                      Change Password
                    </a>
                  </div>
                </div>
                <div className="navbar-item">
                  <div className="buttons">
                    <a className="button is-light" onClick={props.logOut}>
                      Log Out
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
