import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Table } from "antd";
import Axios from "axios";

export default function CloudPaymentRecords() {
  const [recordsData, setRecordsData] = useState([]);
  const [restNames, setRestNames] = useState([]);
  const [restStatus, setRestStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const tableColumns = [
    {
      title: "Restaurant",
      dataIndex: ["branchId", "name"], // Updated for correct nested access
      key: "branchId.name",
      filters: restNames,
      onFilter: (value, record) => record.branchId.name.indexOf(value) === 0,
      sortDirections: ["descend"],
    },
    {
      title: "Card Number",
      key: "cardNum",
      dataIndex: "cardNum",
    },
    {
      title: "Card Type",
      dataIndex: "cardType",
      key: "cardType",
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Machine",
      dataIndex: "machineType",
      key: "machineType",
      filters: [
        { text: "15inchi", value: "15inchi", key: "15inchi" },
        { text: "6inchi", value: "6inchi", key: "6inchi" },
      ],
      onFilter: (value, record) => record.machineType.indexOf(value) === 0,
      sortDirections: ["descend"],
    },
    {
      title: "Status",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      filters: restStatus,
      onFilter: (value, record) => record.transactionStatus.indexOf(value) === 0,
      sortDirections: ["descend"],
    },
    {
      title: "Amount",
      dataIndex: "billAmount",
      key: "billAmount",
    },
    {
      title: "Tip Amount",
      dataIndex: "tipAmount",
      key: "tipAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Date",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sortDirections: ["ascend", "descend"],
      render: (_, record) => <h1>{new Date(record.createdAt).toLocaleString()}</h1>,
    },
  ];

  useEffect(() => {
    getPaymentRecords(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const getPaymentRecords = async (page, limit) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const res = await Axios.get(`${endpoint}/api/v1/employee/orders/getpaymentsocketdetails`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        params: { page, limit }, // Pass pagination params to API
      });

      console.log("---payment records---", res.data);

      setRecordsData(res.data.data);
      setTotalRecords(res.data.total); // Ensure API returns total count for pagination

      // Extract filters dynamically
      const restNames = [];
      const restStatus = [];
      res.data.data.forEach((e) => {
        if (!restNames.some((obj) => obj.text === e.branchId.name)) {
          restNames.push({ text: e.branchId.name, value: e.branchId.name, key: e.branchId.name });
        }
        if (!restStatus.some((obj) => obj.text === e.transactionStatus)) {
          restStatus.push({ text: e.transactionStatus, value: e.transactionStatus, key: e.transactionStatus });
        }
      });

      setRestNames(restNames);
      setRestStatus(restStatus);
    } catch (err) {
      console.error("Error fetching records:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div>
      <h1>Cloud Payment Records</h1>

      <Table
        bordered
        loading={loading}
        dataSource={recordsData}
        columns={tableColumns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "500", "1000", "5000", "10000", "50000", "100000"],  
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}


// // import React, { Component, Fragment } from "react";
// import React, { useEffect, useState } from "react";
// import endpoint from "../../helpers/Endpoint";
// import { Checkbox, Table } from "antd";
// import Axios from "axios";

// export default function CloudPaymentRecords() {
//   const [recordsData, setRecordsData] = useState([]);
//   const [restNames, setRestNames] = useState([]);
//   const [restStatus, setRestStatus] = useState([]);

//   const [loading, setLoading] = useState(true);
  
//   const tableColumns = [
//     {
//       title: "Restaurant",
//       dataIndex: "branchId.name",
//       key: "branchId.name",
//       filters: restNames,
//       onFilter: (value, record) => record.branchId.name.indexOf(value) === 0,
//       sortDirections: ["descend"],
//     },

//     {
//       title: "Card Number",
//       key: "cardNum",
//       dataIndex: "cardNum",
//     },

//     {
//       title: "Card Type",
//       dataIndex: "cardType",
//       key: "cardType",
//     },

//     {
//       title: "Transaction Id",
//       dataIndex: "transactionId",
//       key: "transactionId",
//     },

//     {
//       title: "Machine",
//       dataIndex: "machineType",
//       key: "machineType",
//       filters: [
//         {
//           text : '15inchi',
//           value : '15inchi',
//           key : '15inchi'
//         }, 
//         {
//           text : '6inchi',
//           value : '6inchi',
//           key : '6inchi'
//         }
//       ],
//       onFilter: (value, record) =>
//       record.machineType.indexOf(value) === 0,
//       sortDirections: ["descend"],
//     },

//     {
//       title: "Status",
//       dataIndex: "transactionStatus",
//       key: "transactionStatus",
//       filters: restStatus,
//       onFilter: (value, record) =>
//       record.transactionStatus.indexOf(value) === 0,
//       sortDirections: ["descend"],
//     },

//     {
//       title: "Amount",
//       dataIndex: "billAmount",
//       key: "billAmount",
//     },

//     {
//       title: "Tip Amount",
//       dataIndex: "tipAmount",
//       key: "tipAmount",
//     },
//     {
//       title: "Total Amount",
//       dataIndex: "totalAmount",
//       key: "totalAmount",
//     },

//     {
//       title: "Date",
//       key: "createdAt",
//       field: "date",
//       sorter: (a, b) => {
//         return new Date(a.createdAt) - new Date(b.createdAt);
//       },
//       sortDirections: ["ascend", "descend"],
//       render: (text, record) => (
//         <>
//           <h1>{new Date(record.createdAt).toLocaleString()}</h1>
//         </>
//       ),
//     },
//   ];

//   useEffect(() => {
//     console.log("---fetch information----");
//     getPaymentRecords();
//   }, []);

//   const getPaymentRecords = async () => {
//     console.log("---postdata for group report---");

//     setLoading(true);

//     const token = localStorage.getItem("token");

//     console.log("----token----", token);

//     await Axios.get(
//       `${endpoint}/api/v1/employee/orders/getpaymentsocketdetails`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-auth-token": token,
//         },
//       }
//     )
//     .then((res) => {
//       // handle success
//       console.log("---payment records---", res.data.data);
//       setLoading(false);
//       setRecordsData(res.data.data);

//       let restNames = [];
//       let restStatus = [];

//       res.data.data.map((e) => {

//         if (!restNames.some((obj) => obj.text === e.branchId.name)) {
//           let name = {};
//           name.text = e.branchId.name;
//           name.value = e.branchId.name;
//           name.key = e.branchId.name;
//           restNames.push(name);
//         }
        
//         if (!restStatus.some((obj) => obj.text === e.transactionStatus)) {
//           let status = {};
//           status.text = e.transactionStatus;
//           status.value = e.transactionStatus;
//           status.key = e.transactionStatus;
//           restStatus.push(status);
//         }
      
//       });

//       console.log("----name & status---", restNames, restStatus);

//       setRestNames(restNames);
//       setRestStatus(restStatus);

//     })
//     .catch((err) => {
//       console.error("----error in getting records----", err);
//     });

//   };


//   return (
//     <div>
//       <h1>Cloud Payment Records</h1>
//       <Table
//         bordered
//         loading={loading}
//         dataSource={recordsData}
//         columns={tableColumns}
//       ></Table>
//     </div>
//   );

// }